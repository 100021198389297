import { useAuth } from ".";

export const setUserInfo = (res) => {
  useAuth.setState(() => ({ userInfo: res }));
};

export const setAccessToken = (res) => {
  useAuth.setState(() => ({ accessToken: res }));
};

export const setRefreshToken = (res) => {
  useAuth.setState(() => ({ refreshToken: res }));
};

export const setUserDetail = (res) => {
  useAuth.setState(() => ({ userDetail: res }));
};

export const setMessageCount = (res) => {
  useAuth.setState(() => ({ messageCount: res }));
};
