export const filterByRole = (data, loggedInRole) => {
  let tempArray = [];
  tempArray = data
    ?.filter((item) => {
      return item?.role?.includes(loggedInRole);
    })
    .map((item) => {
      if (item?.children) {
        let childArr = filterByRole(item?.children, loggedInRole);
        return {
          ...item,
          children: childArr,
        };
      } else {
        return item;
      }
    });
  return tempArray ?? [];
};
export const filterByPermission = (data, adminPermission) => {
  let tempArray = [];
  let adminPermissionFilter = adminPermission?.filter(item => item.status).map((item => item.permissionTitle)) || []
  tempArray = data
    ?.filter((item) => {
      return adminPermissionFilter.includes(item.permissionTitle) || item.permissionTitle === 'public';
    })
    .map((item) => {
      if (item?.children) {
        let childArr = filterByPermission(item?.children, adminPermission);
        return {
          ...item,
          children: childArr,
        };
      } else {
        return item;
      }
    });
  return tempArray ?? [];
};
