import React from "react";

export const Svg = (props) => {
  const { children } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      {children}
    </svg>
  );
};
