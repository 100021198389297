import { useToastMessages } from ".";

export const setMessage = (res) => {
  useToastMessages.setState(() => ({ message: res }));
};

export const clearMessage = () => {
  useToastMessages.setState(() => ({ message: null }));
};

export const setStatus = (res) => {
  useToastMessages.setState(() => ({ status: res }));
};
