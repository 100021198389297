import { useNavigate } from "react-router-dom";
import {
  setUserDetail,
  setUserInfo,
} from "../../zustand-store/auth-store/actions";
import { Button } from "../button";
import styles from "./styles.module.scss";

const ErrorBoundaryUi = ({ minHeight = "500px" }) => {
  const navigate = useNavigate();
  return (
    <section
      className={styles.errorContainer}
      style={{ minHeight: `${minHeight}` }}
    >
      <p className={styles.errorMessage}> Oops! Something went wrong.</p>
      <Button
        label="Go back to login page"
        onClick={() => {
          setUserInfo({}),
            sessionStorage?.clear(),
            navigate("/"),
            setUserDetail({});
        }}
      />
    </section>
  );
};

export default ErrorBoundaryUi;
