import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const initialState = {
  userInfo: {},
  accessToken: "",
  refreshToken: "",
  userDetail: {},
  messageCount: null,
};

export const useAuth = create(
  persist(
    () => ({
      ...initialState,
    }),
    {
      name: "userDetails",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
