import { lazy } from "react";

export const LazyLogin = lazy(() => import("../pages/login"));
export const LazyResetPassword = lazy(() => import("../pages/reset-password"));
export const LazyForgotPassword = lazy(() =>
  import("../pages/forgot-password")
);
export const LazyMainLayout = lazy(() => import("../pages/main-layout"));
export const LazyDashboard = lazy(() => import("../pages/dashboard"));
export const LazyUserManagement = lazy(() =>
  import("../pages/user-management")
);
export const LazyCourseSetup = lazy(() => import("../pages/course-setup"));
export const LazyTicketManagement = lazy(() =>
  import("../pages/ticket-management")
);
export const LazyTutorDb = lazy(() => import("../pages/tutor-db"));
export const LazyStudentDb = lazy(() => import("../pages/student-db"));
export const LazyInvoice = lazy(() => import("../pages/invoice"));

export const LazyStudentProfile = lazy(() =>
  import("../pages/student-profile")
);

export const LazyTutorProfile = lazy(() => import("../pages/tutor-profile"));
export const LazyTutorAvailability = lazy(() =>
  import("../pages/availability")
);
export const LazySchedule = lazy(() => import("../pages/schedule"));
export const LazyTutorCourseManagement = lazy(() =>
  import("../pages/course-management")
);

export const LazyScheduling = lazy(() =>
  import("../pages/scheduling-criteria")
);
export const LazyCourseDb = lazy(() => import("../pages/course-db"));
export const LazySubjectDb = lazy(() => import("../pages/subject-db"));
export const LazyNotify = lazy(() => import("../pages/notify"));
export const LazyPageNotFound = lazy(() => import("../pages/pageNot-found"));

export const LazyReports = lazy(() => import("../pages/reports"));
export const LazyDashboardShowAll = lazy(() =>
  import("../ui-components/dashboard-priority/showAll-dashboard")
);

export const LazyUserPermission = lazy(() =>
  import("../pages/user-permission")
);
export const LazyShowAllNextLessons = lazy(() =>
  import("../pages/dashboard/next-lessons-dashboardTable/showAllNextLessons")
);
export const LazyShowAllLastLessons = lazy(() =>
  import("../pages/dashboard/last-lessons-dashboardTable/showAllLastLessons")
);

export const LazyTermsAndCondition = lazy(() =>
  import("../pages/terms-and-condition")
);

export const LazyPrivacyPolicy = lazy(() => import("../pages/privacy-policy"));
