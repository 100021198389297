import * as lazy from "./lazyLoadComponents";
import { ErrorBoundaryHoc } from "../ui-components/error-boundary-hoc";
import { ProtectedRoutes } from "../components/protected-routes";
import { roles } from "../constants/roles";

export const routesData = [
  {
    path: "*",
    element: <lazy.LazyPageNotFound />,
    permissionTitle: "public",
    role: [roles.admin, roles.superAdmin, roles.student, roles.tutor],
  },
  {
    path: "/",
    element: (
      <ErrorBoundaryHoc>
        <lazy.LazyLogin />
      </ErrorBoundaryHoc>
    ),
    permissionTitle: "public",

    role: [roles.admin, roles.superAdmin, roles.student, roles.tutor],
  },
  {
    path: "/forgot-password",
    element: (
      <ErrorBoundaryHoc>
        <lazy.LazyForgotPassword />
      </ErrorBoundaryHoc>
    ),
    permissionTitle: "public",

    role: [roles.admin, roles.superAdmin, roles.student, roles.tutor],
  },
  {
    path: "/reset-password",
    element: (
      <ErrorBoundaryHoc>
        <lazy.LazyResetPassword />
      </ErrorBoundaryHoc>
    ),
    permissionTitle: "public",
    role: [roles.admin, roles.superAdmin, roles.student, roles.tutor],
  },
  {
    path: "/terms-condition",
    element: (
      <ErrorBoundaryHoc>
        <lazy.LazyTermsAndCondition />
      </ErrorBoundaryHoc>
    ),
    permissionTitle: "public",
    role: [roles.admin, roles.superAdmin, roles.student, roles.tutor],
  },
  {
    path: "/privacy-policy",
    element: (
      <ErrorBoundaryHoc>
        <lazy.LazyPrivacyPolicy />
      </ErrorBoundaryHoc>
    ),
    permissionTitle: "public",
    role: [roles.admin, roles.superAdmin, roles.student, roles.tutor],
  },
  {
    path: "/*",
    element: (
      <ProtectedRoutes>
        <ErrorBoundaryHoc>
          <lazy.LazyMainLayout />
        </ErrorBoundaryHoc>
      </ProtectedRoutes>
    ),
    permissionTitle: "public",
    role: [roles.admin, roles.superAdmin, roles.student, roles.tutor],
    children: [
      {
        path: "dashboard",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyDashboard />
          </ErrorBoundaryHoc>
        ),
        permissionTitle: "public",
        role: [roles.admin, roles.superAdmin, roles.tutor, roles.student],
      },
      {
        path: "dashboard/next-lessons",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyShowAllNextLessons />
          </ErrorBoundaryHoc>
        ),
        permissionTitle: "public",
        role: [roles.tutor, roles.student],
      },
      {
        path: "dashboard/last-lessons",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyShowAllLastLessons />
          </ErrorBoundaryHoc>
        ),
        permissionTitle: "public",
        role: [roles.tutor, roles.student],
      },
      {
        path: "dashboard/high-priority",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyDashboardShowAll />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "public",

        role: [roles.admin, roles.superAdmin, roles.tutor, roles.student],
      },
      {
        path: "dashboard/medium-priority",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyDashboardShowAll />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "public",

        role: [roles.admin, roles.superAdmin, roles.tutor, roles.student],
      },
      {
        path: "dashboard/low-priority",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyDashboardShowAll />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "public",
        role: [roles.admin, roles.superAdmin, roles.tutor, roles.student],
      },
      {
        path: "user-management",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyUserManagement />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "User Management",
        role: [roles.admin, roles.superAdmin],
      },
      {
        path: "course-setup",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyCourseSetup />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Course Setup",
        role: [roles.admin, roles.superAdmin],
      },
      {
        path: "ticket-management",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyTicketManagement />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Ticket Management",
        role: [roles.tutor],
      },
      {
        path: "tutor-db",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyTutorDb />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Tutor DB",
        role: [roles.admin, roles.superAdmin],
      },
      {
        path: "tutor-profile",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyTutorProfile />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Tutor Profile",
        role: [roles.tutor],
      },
      {
        path: "student-db",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyStudentDb />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Student DB",
        role: [roles.admin, roles.superAdmin],
      },
      {
        path: "invoice",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyInvoice />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Invoice",
        role: [roles.student],
      },
      // {
      //   path: "scheduling",
      //   element: <>Scheduling</>,
      //   role: [roles.admin, roles.superAdmin],
      // },
      {
        path: "reports",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyReports />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Reports",
        role: [roles.admin, roles.superAdmin],
      },
      {
        path: "settings",
        element: <>Settings</>,
        permissionTitle: "Settings",
        role: [roles.admin, roles.superAdmin],
      },
      {
        path: "student-profile",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyStudentProfile />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Student Profile",
        role: [roles.student],
      },
      {
        path: "availability",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyTutorAvailability />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Availability",
        role: [roles.tutor, roles.student],
      },
      {
        path: "course-management",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyTutorCourseManagement />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Course Management",
        role: [roles.admin, roles.superAdmin, roles.tutor, roles.student],
      },
      {
        path: "tutor-schedule",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazySchedule />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Tutor Schedule",
        role: [roles.tutor],
      },
      {
        path: "student-schedule",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazySchedule />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Student Schedule",
        role: [roles.student],
      },
      {
        path: "schedule",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyScheduling />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Scheduling",
        role: [roles.admin, roles.superAdmin],
      },
      {
        path: "course-db",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyCourseDb />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Course DB",
        role: [roles.admin, roles.superAdmin],
      },
      {
        path: "subject-db",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazySubjectDb />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Subject DB",
        role: [roles.admin, roles.superAdmin],
      },
      {
        path: "scheduling-details",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazySchedule />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Schedule Details",
        role: [roles.admin, roles.superAdmin],
      },
      {
        path: "notify",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyNotify />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "Notify",
        role: [roles.admin, roles.superAdmin],
      },
      {
        path: "user-permission",
        element: (
          <ErrorBoundaryHoc>
            <lazy.LazyUserPermission />
          </ErrorBoundaryHoc>
        ),

        permissionTitle: "User Permission",
        role: [roles.superAdmin],
      },
    ],
  },
];
