import { create } from "zustand";

const initialState = {
  message: null,
  status: null,
};

export const useToastMessages = create(() => ({
  ...initialState,
}));
