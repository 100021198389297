import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ErrorBoundaryHoc } from "../ui-components/error-boundary-hoc";
import { InitialLoadUi } from "../components/initial-load-ui";
import { useAuth } from "../zustand-store/auth-store";
import { ToastMessage } from "../components/toast-messages";
import { useToastMessages } from "../zustand-store/toastMessages-store";
import { filterByPermission, filterByRole } from "../utils/filterByRoleUtil";
import { routesData } from "../routes/routes";
import { useMemo } from "react";
import { createSignalRContext } from "react-signalr";
import { api } from "../config/apiEndPoints";

export const SignalRContext = createSignalRContext();

const Shell = () => {
  const { message, status } = useToastMessages();
  const userRole = useAuth?.getState()?.userInfo;
  const token = userRole?.token;

  const useAuthRoutes = useMemo(() => {
    if (userRole?.roleType?.length) {
      if (userRole?.isWebLoggedInFirstTime) {
        alert(
          "For system to work efficiently, please make sure that system Date&Time settings are set on a 24 hour format"
        );
      }
      return userRole?.adminPermission
        ? filterByPermission(routesData, userRole.adminPermission)
        : filterByRole(routesData, userRole.roleType);
    }
    return routesData;
  }, [userRole]);

  /*
  Updated the rendering logic of routes with usememo 
  in future if there is any issues related to routing
  no record found uncomment below code and remove 
  above ones
  */

  // const useAuthRoutes = () => {
  //   const getUserRole = useAuth?.getState()?.userInfo;
  //   let routes = routesData;
  //   if (getUserRole?.roleType?.length) {
  //     routes = filterByRole(routesData, getUserRole?.roleType);
  //   }
  //   return routes;
  // };

  // useEffect(() => {
  //   useAuthRoutes();
  // }, [getUserRole]);

  return (
    <>
      <SignalRContext.Provider
        connectEnabled={!!token}
        accessTokenFactory={() => token}
        dependencies={[token]} //remove previous connection and create a new connection if changed
        url={api.baseSignalR}
        // logger={(log) => console.log(log)}
      >
        <ToastMessage message={message} status={status} />
        <ErrorBoundaryHoc>
          <Suspense fallback={<InitialLoadUi />}>
            {useAuthRoutes && (
              <RouterProvider router={createBrowserRouter(useAuthRoutes)} />
            )}
          </Suspense>
        </ErrorBoundaryHoc>
      </SignalRContext.Provider>
    </>
  );
};

export default Shell;
