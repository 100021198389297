import React from "react";
import cn from "classnames";
import styles from "./style.module.scss";

export const Button = React.forwardRef((props, ref) => {
  const { label, className, style, ...restProps } = props;
  return (
    <button
      className={cn(styles.buttonContainer, className)}
      style={style}
      ref={ref}
      {...restProps}
    >
      {label}
    </button>
  );
});
