import Image from "../image";
import image from "../../assests/images/connexcel-logo2.png";
import blurImage from "../../assests/images/connexcel-logo-blur-dashboard.jpg";

export const InitialLoadUi = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: `translate(${-50}%, ${-50}%)`,
        width: "150px",
      }}
    >
      <Image loadingSrc={blurImage} src={image} />
    </div>
  );
};
