import { Navigate } from "react-router-dom";
import { useAuth } from "../../zustand-store/auth-store";

export const ProtectedRoutes = ({ children }) => {
  const getToken = useAuth?.getState()?.userInfo?.token;

  if (!getToken?.length) {
    return <Navigate to="/" />;
  } else if (getToken?.length) {
    return children;
  } else {
    return <Navigate to="/notpermitted" />;
  }
};
